import React from "react";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Content from "./Content";

const App: React.FC = () => {
    const router = createBrowserRouter([
        {
            path: "/*",
            element: <Content />,
        },
    ]);

    return <RouterProvider router={router} />;
};

export default App;

import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";

import logoOld from "./images/logo-old.png";
import logoNew from "./images/logo-new.png";

const Content: React.FC = () => {
    const location = useLocation();

    const [timer, setTimer] = useState(5);

    useEffect(() => {
        setInterval(() => {
            setTimer((state) => (state > 0 ? state - 1 : state));
        }, 1000);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.location.href =
                "https://deepfabric.ai" + location.pathname + location.search;
        }, 10000);
    }, [location.pathname, location.search]);

    return (
        <div className="h-screen w-screen flex flex-col gap-4 justify-center items-center">
            <div className="flex flex-wrap gap-4 items-center">
                <img className="h-8" src={logoOld} alt="Time.Dev" />
                <p className="text-2xl">is now</p>
                <img className="h-8" src={logoNew} alt="DeepFabric" />
            </div>
            <p className="text-base text-neutral-500 font-semibold">Please use your time.dev credentials to sign into DeepFabric</p>
            <p className="text-sm text-neutral-500 font-semibold">{`Redirecting in ${timer} seconds`}</p>
        </div>
    );
};

export default Content;
